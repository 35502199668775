import { publicGetMethodService } from "services";

let app_name = process.env.REACT_APP_APP_NAME;
export const SETTING_JSON = app_name === "1xmix" ? `https://1xmix.s3.ap-southeast-1.amazonaws.com/control-1xmix.json`: `https://sportsxzone.s3.ap-southeast-1.amazonaws.com/control.json`;

let domains = {};

export const fetchDomains = async () => {
  const res = await publicGetMethodService(SETTING_JSON);
  domains = {
    main_domain: app_name === "1xmix" ? process.env.REACT_APP_MAIN_DOMAIN :(res? res?.REACT_APP_MAIN_DOMAIN : process.env.REACT_APP_MAIN_DOMAIN),
    soccer_domain: app_name === "1xmix" ? process.env.REACT_APP_SOCCER_DOMAIN :(res? res?.REACT_APP_SOCCER_DOMAIN: process.env.REACT_APP_SOCCER_DOMAIN),
    additional_domain: app_name === "1xmix" ? process.env.REACT_APP_ADDITIONAL_DOMAIN :(res? res?.REACT_APP_ADDITIONAL_DOMAIN: process.env.REACT_APP_ADDITIONAL_DOMAIN),
    slot_domain: app_name === "1xmix" ? process.env.REACT_APP_SLOT_DOMAIN :(res? res?.REACT_APP_SLOT_DOMAIN: process.env.REACT_APP_SLOT_DOMAIN),
    lottery_domain: app_name === "1xmix" ? process.env.REACT_APP_LOTTERY_DOMAIN :(res? res?.REACT_APP_LOTTERY_DOMAIN: process.env.REACT_APP_LOTTERY_DOMAIN),
    config_domain: app_name === "1xmix" ? process.env.REACT_APP_MAIN_DOMAIN :(res? res?.config_api_domain : process.env.REACT_APP_MAIN_DOMAIN),
  };
};

await fetchDomains();
setInterval(fetchDomains, 1000 * 10);

let isProduction = process.env.REACT_APP_APP_ENV === 'production';

const getDomain = (key) => {
  return isProduction 
    ? domains[key] || process.env[`REACT_APP_${key.toUpperCase()}`] 
    : process.env[`REACT_APP_${key.toUpperCase()}`];
};

// Main API
export const ADMIN_LOGIN_API = () => `${getDomain('main_domain')}api/main/auth/admin-login`;
export const ADMIN_LOGOUT_API = () => `${getDomain('main_domain')}api/main/auth/logout`;
export const AGENT_LOGIN_API = () => `${getDomain('main_domain')}api/main/auth/login/agent`;
export const CHANGE_PASSWORD_API = () => `${getDomain('main_domain')}api/main/users/change-password`;
export const USERS_BALANCES_API = () => `${getDomain('main_domain')}api/main/users/dashboard/total-balance`;
export const USERS_COUNT_API = () => `${getDomain('main_domain')}api/main/users/dashboard/total-user`;
export const DOWNLINE_PASSWORD_CHANGE_API = id => `${getDomain('main_domain')}api/main/users/change-password/${id}`;
export const USER_DETAIL_API = id => `${getDomain('main_domain')}api/main/users/${id}`;
export const SUB_USERS_API = () => `${getDomain('main_domain')}api/main/users/sub-accounts`;
export const SETTINGS_API = () => `${getDomain('config_domain')}api/main/config?is_cache=false`;
export const EDIT_SETTINGS_API = () => `${getDomain('config_domain')}api/main/config/update`;
export const USER_CREATE_API = () => `${getDomain('main_domain')}api/main/users`;
export const USER_EDIT_CONFIG_API = id => `${getDomain('main_domain')}api/main/users/config/${id}`;
export const USER_EDIT_COMMISSION_API = id => `${getDomain('main_domain')}api/main/users/commission/${id}`;
export const PAYMENT_SUBMIT_API = () => `${getDomain('main_domain')}api/main/wallets/payments`;

export const DEPOSIT_HISTORY_API = () => `${getDomain('main_domain')}api/main/wallets/payments/member/deposit-histories`;
export const DEPOSIT_STATUS_API = () => `${getDomain('main_domain')}api/main/wallets/payments/member/confirm-deposit`;

export const WITHDRAW_HISTORY_API = () => `${getDomain('main_domain')}api/main/wallets/payments/member/withdrawal-histories`;
export const WITHDRAW_STATUS_API = () => `${getDomain('main_domain')}api/main/wallets/payments/member/confirm-withdrawal`;
export const PAYS_API = () => `${getDomain('main_domain')}api/main/wallets/payments/methods`;
export const ACCOUNTS_API = () => `${getDomain('main_domain')}api/main/wallets/payments/accounts`;

// Slot API
export const SLOT_BETTING_API = () => `${getDomain('slot_domain')}api/games/slot/slot-bettings`;
export const GAME_BALANCE_API = () => `${getDomain('slot_domain')}api/games/slot/game-balance`;
export const SLOT_BALANCE_API = () => `https://syscasino.com/api/games/slot/max-accept-limit`;

// Soccer API
export const TEX_SETTING_UPDATE_API = id => `${getDomain('soccer_domain')}api/soccer/bettings/tax-percent/${id}`;
export const FIXTURES_API = () => `${getDomain('soccer_domain')}api/soccer/fixtures/third-party/upcomming-events`;
export const SEARCH_FIXTURES_API = () => `${getDomain('soccer_domain')}api/soccer/fixtures/third-party/search-events`;
export const FIXTURE_API = () =>  `${getDomain('soccer_domain')}api/soccer/fixtures`;
export const FIXTURES_UPDATE_API = id =>  `${getDomain('soccer_domain')}api/soccer/fixtures/${id}`;
export const FIXTURES_OPEN_ALL_API = () => `${getDomain('soccer_domain')}api/soccer/fixtures/all/change-is-published`
export const LEAGUES_API = () => `${getDomain('soccer_domain')}api/soccer/fixtures/leagues`;
export const ODDS_API = id => `${getDomain('soccer_domain')}api/soccer/fixtures/third-party/odds/${id}`;
export const LIVE_GOALS_API = id => `${getDomain('soccer_domain')}api/soccer/fixtures/third-party/events/${id}`
export const RESULT_CONFIRM_API = id => `${getDomain('soccer_domain')}api/soccer/fixtures/confirm-score/${id}`
export const RESULT_REJECT_API = id => `${getDomain('soccer_domain')}api/soccer/fixtures/reject-score/${id}`;
export const REJECT_SLIP_API = id => `${getDomain('soccer_domain')}api/soccer/bettings/reject/${id}`
export const TEAMS_API = () => `${getDomain('soccer_domain')}api/soccer/fixtures/names`;
export const EDIT_TEAM_API = id => `${getDomain('soccer_domain')}api/soccer/fixtures/names/${id}`;
export const TEAM_IMAGE = (data) => {
  let encodedString = data?.code;
    return `${getDomain('soccer_domain')}api/soccer/fixtures/images/${encodedString}/teams/${data?.name_en}`;
  };
export const ROLLBACK_API = id => `${getDomain('soccer_domain')}api/soccer/fixtures/rollback-score/${id}`;
export const TRANSLATE_API = () => `${getDomain('soccer_domain')}api/soccer/fixtures/translate/`;
export const MEMBER_OUTSTANDING_API = () => `${getDomain('soccer_domain')}api/soccer/bettings/member-outstanding`;
export const REPORT_NOTI_SENT_API = () => `${getDomain('soccer_domain')}api/soccer/fixtures/send-noti`;

// Lottery API
export const THREED_ODDS_API = () => `${getDomain('lottery_domain')}api/lottery/config/odds`;
export const THREED_OPEN_CLOSE_API = () => `${getDomain('lottery_domain')}api/lottery/config/disable-schedules`;
export const DIGITS_API = () => `${getDomain('lottery_domain')}api/lottery/numbers`;
export const DIGITS_EDIT_API = () => `${getDomain('lottery_domain')}api/lottery/numbers/set-limit-amount`;
export const RESET_DIGITS_API = () => `${getDomain('lottery_domain')}api/lottery/numbers/reset`;
export const LUCKY_NUMBER_API = () => `${getDomain('lottery_domain')}api/lottery/numbers/lucky-numbers`;
export const LUCKY_NUMBER_CREATE_API = () => `${getDomain('lottery_domain')}api/lottery/numbers/lucky-number-create`;
export const LUCKY_NUMBER_APPORVE_API = id =>`${getDomain('lottery_domain')}api/lottery/numbers/lucky-number-win-lose/${id}`;
export const LUCKY_NUMBER_EDIT_API = id =>`${getDomain('lottery_domain')}api/lottery/numbers/lucky-number-update/${id}`;
export const BET_HISTORY_API = () => `${getDomain('lottery_domain')}api/lottery/numbers/lucky-draw`;
export const BETTING_HISTORY_API = () => `${getDomain('lottery_domain')}api/lottery/numbers/bettings`;
export const STOCK_API = () => `${getDomain('lottery_domain')}api/lottery/report/out-standing`;
export const REPORT_API = () => `${getDomain('lottery_domain')}api/lottery/report/3d-report`;
export const THREED_MEMBER_OUTSTANDING_API = () => `${getDomain('lottery_domain')}api/lottery/report/out-standing-balance`;

// Additional API
export const COMMUNITY_API = () => `${getDomain('additional_domain')}api/cms/posts`;
export const CREATE_COMMUNITY_API = () => `${getDomain('additional_domain')}api/cms/posts/create`;
export const MESSAGE_API = () => `${getDomain('additional_domain')}api/cms/noti/messages`;
export const CREATE_MESSAGE_API = () => `${getDomain('additional_domain')}api/cms/noti/create`;
export const SEND_MESSAGE_API = id => `${getDomain('additional_domain')}api/cms/noti/send-noti/${id}`;