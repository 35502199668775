import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'
import { 
	AUTH_PREFIX_PATH, 
	UNAUTHENTICATED_ENTRY, 
	REDIRECT_URL_KEY 
} from 'configs/AppConfig'
import Cookies from 'js-cookie';
import { decodeAuth } from 'utils/Helper';

const ProtectedRoute = () => {
	const { pathname } = useLocation();
	const getAuth = Cookies.get('vb\C#^*gW589');
	const dcode = getAuth ? decodeAuth(getAuth): null;
	let getRole = localStorage.getItem("last_login_role");
	const params = useParams();
	// useEffect(() => {
	// 	if(
	// 		pathname !== "/report/win-lose-report" && 
	// 		pathname !== `/bet-list/user/${params?.id}` && 
	// 		pathname !== '/report/win-lose-report-slot' && 
	// 		pathname !== '/report/win-lose-report-slot/v2' && 
	// 		pathname !== `/slot-bet-list/users/${params?.id}` &&
	// 		pathname !== `/stock-management/full-time` &&
	// 		pathname !== `/stock-management/half-time` &&
	// 		pathname !== `/stock-management/even-odd` &&
	// 		pathname !== `/user-transactions/wallet` &&
	// 		pathname !== `/transactions` 
	// 	){
	// 		localStorage.removeItem("report_downline");
	// 		localStorage.removeItem("report_date");
	// 		localStorage.removeItem("report_range");
	// 	};
	// }, [pathname]);
	
	if (!getAuth) {
		return <Navigate to={`${AUTH_PREFIX_PATH}/${getRole === 'admin'? 'admin':'agent'}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${pathname}`} replace />;
	};
	
	if(!dcode?.user?.is_password_changed){
		return <Navigate to={`${AUTH_PREFIX_PATH}/change-password`} replace />;
	};

	return <Outlet />
	
}

export default ProtectedRoute