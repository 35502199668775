import { AUTH_PREFIX_PATH, AUTHENTICATED_ENTRY, REDIRECT_URL_KEY, UNAUTHENTICATED_ENTRY } from 'configs/AppConfig';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classes from 'assets/styles/Maintenance.module.css';

const Maintenance = () => {
    const navigate = useNavigate();
    const {settings} = useSelector((state) => state.setting);
    const {settingsAgent} = useSelector((state) => state.settingAgent);
  
    useEffect(()=>{
          if(settings){
            if(settings?.status){
              let getRole = localStorage.getItem("last_login_role");
                if(getRole === 'admin'){
                  return navigate(`${AUTH_PREFIX_PATH}/admin${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}`);
                }else{
                  return navigate(`${AUTH_PREFIX_PATH}/agent${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}`);
                };
            };
          }else{
              if(settingsAgent?.status){
                  return navigate(`${AUTHENTICATED_ENTRY}`);
                  // return navigate(`${AUTH_PREFIX_PATH}/agent${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}`);
              };
          };
    },[settings,settingsAgent]);
  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <div className={classes.animation}>
          <div className={`${classes.one} ${classes['spin-one']}`}></div>
          <div className={`${classes.two} ${classes['spin-two']}`}></div>
          <div className={`${classes.three} ${classes['spin-one']}`}></div>
        </div>
      <h1 className={classes.title}>Under maintenance</h1>
      <p className={classes.desc}>{settings?.remark || (settingsAgent?.remark || '')}</p>
      </div>
    </div>
  )
}

export default Maintenance
