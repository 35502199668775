import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter  } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import './lang'
import mockServer from './mock'

const app_name = process.env.REACT_APP_APP_NAME;

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const environment = process.env.NODE_ENV

if (environment !== 'production') {
	mockServer({ environment })
};

function App() {
  useEffect(() => {
    // Dynamic Title
    document.title = app_name === "1xmix" ? "Mix  | Dashboard":"SportsXZone  | Dashboard";
    const favicon = document.getElementById("favicon");
    if (favicon) {
      favicon.href = app_name === "1xmix" ? "/img/1xMix_logo.png" : "/img/main_logo_sm.png";
    }
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider 
            themeMap={themes} 
            defaultTheme={THEME_CONFIG.currentTheme} 
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>  
      </Provider>
    </div>
  );
}

export default App;
