import React, { useEffect } from 'react';
import { onBlankLayout } from 'store/slices/themeSlice';
import { useDispatch, useSelector } from 'react-redux';
import Marquee from "react-fast-marquee";
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { decodeAuth } from 'utils/Helper';

const AppRoute = ({ component: Component, routeKey, blankLayout, ...props }) => {

	const dispatch = useDispatch();
	const {settingsAgent} = useSelector((state) => state.settingAgent);
	const {pathname} = useLocation();
	const getAuth = Cookies.get("vb\C#^*gW589");
	let userAuth = getAuth && decodeAuth(getAuth);

	useEffect(() => {
		const isBlank = blankLayout ? true : false
		dispatch(onBlankLayout(isBlank))

	}, [blankLayout]);

	
	return (
		<>
			{
				(settingsAgent?.dashboard_announancement && pathname !== '/admin/login' && pathname !== '/agent/login') &&
				<div 
				style={{
					padding: '0 10px',
					backgroundColor: '#ffec9f',
					borderRadius: '5px',
					marginBottom: '10px',
				}}
				>
					<Marquee 
						pauseOnHover={true}
						gradient={false}
						speed={50}
						style={{
							fontSize: '17px',
							padding: '12px 0'
						}}
					>
						{settingsAgent?.dashboard_announancement} ... &nbsp;
					</Marquee>
				</div>
				
			}
			<Component {...props} />
		</>
	)
}

export default AppRoute