import React, { useEffect, useState } from 'react';
import {io} from "socket.io-client";

const ActiveUsers = () => {
  const [total,setTotals] = useState(0);

  useEffect(()=>{
    let url = process.env.REACT_APP_SOCCER_DOMAIN;
    const socket = io(url,{
        transports: ["websocket"],
        path: '/api/soccer/socket',
    });
    socket.connect();
    socket.on("current_user_count", res => {
        setTotals(res?.data);
    });
    socket.on("hello", res => {
      console.log("hello received", res);
        // setNewOdds(res);
    });
    return () => {
        socket.disconnect();
    };
  },[]);
  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }}>
        <h1 style={{fontSize: '50px'}}>Active Users - {total}</h1>
    </div>
  )
}

export default ActiveUsers
