import React from 'react'

const GetEnv = () => {
    const envVars = Object.entries(process.env)
    .filter(([key]) => key.startsWith("REACT_APP_"))
    .map(([key, value]) => ({ key, value }));
  return (
    <div>
        <h2>Environment Variables</h2>
        <ol>
            {envVars.map(({ key, value }) => (
            <li key={key} style={{marginBottom: '10px'}}>
                <strong>{key}:</strong> <span style={{fontSize: '20px', color: 'red'}}>{value}</span>
            </li>
            ))}
        </ol>
    </div>
  )
}

export default GetEnv
