import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { decodeAuth } from '../utils/Helper';
import Cookies from "js-cookie";
import { publicGetMethodService } from 'services';
import { SETTING_JSON } from 'services/apis';

const fetchDomains = async () => {
  const res = await publicGetMethodService(SETTING_JSON);
  let domains = {
    graphql_endpoint: res? res?.REACT_APP_GRAPHQL_ENDPOINT:process.env.REACT_APP_GRAPHQL_ENDPOINT,
  };
  return domains;
};

let domains = await fetchDomains();

setInterval(async () => {
  domains = await fetchDomains();
  client.setLink(createLink()); 
}, 1000 * 10);

let isProduction = process.env.REACT_APP_APP_ENV === 'production';
let app_name = process.env.REACT_APP_APP_NAME;

const createLink = () => {
  const httpLink = new HttpLink({
    uri: app_name === "1xmix"? process.env.REACT_APP_GRAPHQL_ENDPOINT: (isProduction? domains?.graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT),
  });
  const authLink = setContext((_, { headers }) => {
    const getAuth = Cookies.get('vb\C#^*gW589');
    let userAuth = getAuth && decodeAuth(getAuth);
    let token = `${userAuth?.token}`;
    
    return {
      headers: {
        ...headers,
        authorization: token ? token : "",
      }
    }
  });
  return authLink.concat(httpLink);
};

const client = new ApolloClient({
  link: createLink(),
  cache: new InMemoryCache(),
});

export default client;
